module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CarryKey","short_name":"CarryKey","start_url":"/","description":"CarryKey website portfolio","lang":"en","orientation":"portrait","background_color":"#FDFDFD","theme_color":"#A38ED2","display":"standalone","icon":"src/images/favic/favicon.png","icons":[{"src":"/android-chrome-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/android-chrome-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/android-chrome-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/android-chrome-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/android-chrome-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"include_favicon":true,"cache_busting_mode":"none","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-VFLVK4H74Z","head":true,"exclude":["/merch","/feedback","/search"],"pageTransitionDelay":0,"defer":false,"enableWebVitalsTracking":true,"anonymize":false,"respectDNT":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MFH6SPGB","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
